<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <SideBar class="sm:w-1/5 sm:order-first sm:mt-0 w-full order-last mt-4" />
      <div class="sm:w-4/5 w-full">
        <TitleComponent
          class="py-8"
          title="IP External Fire Test"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Details" size="medium" />
            <a
              :href="
                require('../../assets/IPSeries/ExternalFireDetails-min.png')
              "
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/ExternalFireDetails-min.png"
              />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              A stack of unfinished or finished articles as they appear in the
              manufacturing line is placed on a non-combustible surface (steel
              grate) above a lattice of dried wood soaked with diesel fuel or
              equivalent source. A wire basket or clamps may be used to hold the
              articles in place. Sufficient fuel is used to provide a 30-minute
              fire. The fire is ignited and the material is observed for: a)
              Evidence of detonation, deflagration or explosion of the total
              contents; b) Potentially hazardous fragmentation; and c) Thermal
              effects (i.e. size of the fireball, etc.). Outcome can determine
              the in-process hazard division 1.1, 1.2, 1.3 or 1.4.
            </p>

            <TitleComponent class="py-8" title="Equivalence" size="medium" />
            <p class="p-light pb-6">
              Similar in test principle to
              <router-link class="link" to="/external-fire-test"
                >UN Series 6 (c)</router-link
              >, with the exception that articles are tested without packaging,
              as they are or may be found in the process, including during
              process upset.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objective</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Configuration of articles</td>
                    <td>
                      Simulate a specific in-process configuration (requiring
                      administrative controls to maintain) or a worst-case
                      configuration (confined, random orientation)
                    </td>
                    <td></td>
                    <td>
                      Articles placed in a worst-case configuration (e.g.
                      side-by-side, end-to-end, and/or in a pile)
                    </td>
                  </tr>
                  <tr>
                    <td>Quantity of articles</td>
                    <td>
                      Provide a sufficient quantity of product to enable
                      characterization of any dangerous effect
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      Fuel type (wood, liquid, or gas), configuration, and
                      quantity
                    </td>
                    <td>
                      Create a fire of sufficient intensity and duration that
                      engulfs the product to enable characterization of any
                      dangerous effects
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      Witness screen size, placement, and materials of
                      construction
                    </td>
                    <td>
                      Evidence of fragment energy, and a standard reference
                      point for judging distances
                    </td>
                    <td></td>
                    <td>
                      Three 2m x 2m x 2mm 1100-0 aluminum sheets (Brinell
                      Hardness 23, tensile strength 90 MPa), or equivalent
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment*</th>
                  </tr>
                  <tr>
                    <td>Mass explosion</td>
                    <td>
                      Observation of consumption rate, video camera evidence,
                      and visual post test inspection. Optional use of blast
                      gauges and high-speed video.
                    </td>
                    <td>
                      Comparing observations against the definition of a mass
                      explosion hazard (substantial proportion of the whole
                      [Subjective] explodes) [Mass/ high explosion hazard: IP
                      Division 1.1]
                    </td>
                  </tr>
                  <tr>
                    <td>Potentially hazardous projections</td>
                    <td>
                      Damage to the witness screens; field sampling/ cataloging
                    </td>
                    <td>
                      Potentially hazardous projections [Fragmentation hazard:
                      IP Division 1.2]
                    </td>
                  </tr>
                  <tr>
                    <td>Fireball, jet of flame, or fiery projections</td>
                    <td>
                      Observation of test; video camera evidence (reference
                      marker: witness screens)
                    </td>
                    <td>
                      Mass fire hazard [Mass fire hazard: IP Division 1.3]
                    </td>
                  </tr>
                  <tr>
                    <td>No mass reaction hazard</td>
                    <td>Observation of test; video camera evidence</td>
                    <td>[No mass reaction hazard: IP Division 1.4]</td>
                  </tr>
                  <tr>
                    <td>
                      Reaction is non-propagating and less than 1.0 lbs Net
                      Explosive Weight
                    </td>
                    <td>Observation of test; video camera evidence</td>
                    <td>
                      [No reaction propagation hazard: Qualified IP Division 1.4
                      or 1.4Q]
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="p-light">
                *Assessments are taken together to determine the hazard
                division.
              </p>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-8 pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="HD 1.1 Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166266291?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166266291"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
            <TitleComponent
              class="pt-8"
              title="HD 1.2 Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166266348?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166266348"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
            <TitleComponent
              class="pt-8"
              title="HD 1.3 Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166266428?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166266428"
              allow="autoplay; fullscreen"
              id="player_3"
              name="fitvid2"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 md:pl-2">
            <TitleComponent
              class="pt-8"
              title="HD 1.4 Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166266479?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166266479"
              allow="autoplay; fullscreen"
              id="player_4"
              name="fitvid3"
            ></iframe>
            <TitleComponent
              class="pt-8"
              title="HD 1.4s Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166266500?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166266500"
              allow="autoplay; fullscreen"
              id="player_5"
              name="fitvid4"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/IPSeriesSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
