<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <SideBar class="sm:w-1/5 sm:order-first sm:mt-0 w-full order-last mt-4" />
      <div class="sm:w-4/5 w-full">
        <TitleComponent class="py-8" title="External Fire Test" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="External Fire Test Description"
              size="medium"
            />
            <a
              :href="
                require('../../assets/IPSeries/ExternalFireDetails-min.png')
              "
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/ExternalFireDetails-min.png"
              />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The purpose of completing the external fire test is to determine
              whether there is a dangerous effect (mass explosion, dangerous
              projections, radiant heat and/ or violent burning) when involved
              in a fire. Outcome can determine the hazard division 1.1, 1.2,
              1.3, 1.4, or 1.4S.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objective</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>
                      Product quantity (specified package volume and quantity)
                    </td>
                    <td>
                      Provide a sufficient quantity of product to enable
                      characterization of any dangerous effect
                    </td>
                    <td></td>
                    <td>
                      Volume of the packages or unpackaged article ≥ 0.15 m^3.
                    </td>
                  </tr>
                  <tr>
                    <td>Product placement</td>
                    <td>
                      Ensure that the packages or articles are properly heated
                      by the fire
                    </td>
                    <td></td>
                    <td>
                      A metal grid is used to support the items above the fire.
                      For a wood fire, the items should be 1 m above the ground
                      while for a fuel fire 0.5 m above the ground.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Fuel type (wood, liquid, or gas), configuration, and
                      quantity
                    </td>
                    <td>
                      Create a fire of sufficient intensity and duration that
                      engulfs the product to enable characterization of any
                      dangerous effects
                    </td>
                    <td></td>
                    <td>
                      Enough fuel should be used to keep the fire burning for at
                      least 30 min or until the item(s) have clearly had enough
                      time to sufficiently react. The wood should extend beyond
                      the packages or articles by 1 m on each side. Likewise a
                      liquid or gas based fire should extend beyond the packages
                      by the same amount.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Witness screen size, placement, and materials of
                      construction
                    </td>
                    <td>
                      Evidence of fragment energy, and a standard reference
                      point for judging distances
                    </td>
                    <td></td>
                    <td>
                      Three 2m x 2m x 2mm 1100-0 Aluminum Sheets (Brinell
                      Hardness 23, Tensile Strength 90 MPa). The witness screens
                      should be placed 4 m from the edge of the packages or
                      articles.
                    </td>
                  </tr>
                  <tr>
                    <td>Limited Wind Speed</td>
                    <td>
                      Ensure that all the packages or articles are enveloped in
                      flames
                    </td>
                    <td></td>
                    <td>
                      The test should not be performed under conditions where
                      the wind speed exceeds 6 m/s.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment*</th>
                  </tr>
                  <tr>
                    <td>Mass explosion</td>
                    <td>
                      Observation of consumption rate, video camera evidence,
                      and visual post test inspection. Optional use of blast
                      gauges and high-speed video.
                    </td>
                    <td>
                      Comparing observations against the definition of a mass
                      explosion hazard (substantial proportion of the whole
                      <span style="color: a800000;">[subjective]</span>
                      explodes)
                    </td>
                  </tr>
                  <tr>
                    <td>Metallic projections</td>
                    <td>Damage to the witness screens</td>
                    <td>
                      Perforation (HD 1.2); Indentation &gt;4 mm (HD 1.4);
                      Indentation &lt;4mm (HD1.4S)
                    </td>
                  </tr>
                  <tr>
                    <td>Metallic projections</td>
                    <td>Field sampling/ cataloging</td>
                    <td>
                      Kinetic energy from the distance-mass relation: &gt;20 J
                      (HD 1.2); 8-20 J (HD1.4); &lt;8 J (HD 1.4S)
                    </td>
                  </tr>
                  <tr>
                    <td>Fireball or jet of flame</td>
                    <td>
                      Observation of test; video camera evidence (reference
                      marker: witness screens)
                    </td>
                    <td>&gt;4 m (HD 1.3); 1-4 m (HD 1.4); &lt;1 m (HD 1.4S)</td>
                  </tr>
                  <tr>
                    <td>Fiery projections</td>
                    <td>
                      Observation of test, field sampling, video camera evidence
                      (reference marker: witness screens)
                    </td>
                    <td>
                      &gt;15 m (HD 1.3); 5-15 m (HD 1.4); &lt;5 m (HD 1.4S)
                    </td>
                  </tr>
                  <tr>
                    <td>Heat flux</td>
                    <td>Radiometers (optional)</td>
                    <td>
                      100 kg basis: &gt;4 kW/m2 at 15 m (HD 1.3); 4 kW/m2 at 15
                      m (HD 1.4); &lt;4 kW/m2 at 5 m (HD 1.4S)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Burn time (can be
                      <span style="color: a800000;">[subjective]</span>)
                    </td>
                    <td>Observation of test; video camera evidence</td>
                    <td>
                      100 kg basis: &lt;35 sec (HD 1.3); 35-330 sec (HD 1.4);
                      &gt;330 sec (HD 1.4S)
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="p-light">
                *Assessments are taken together to determine the hazard
                division.
              </p>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-8 pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="HD 1.1 Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166266291?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166266291"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
            <TitleComponent
              class="pt-8"
              title="HD 1.2 Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166266348?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166266348"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
            <TitleComponent
              class="pt-8"
              title="HD 1.3 Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166266428?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166266428"
              allow="autoplay; fullscreen"
              id="player_3"
              name="fitvid2"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 md:pl-2">
            <TitleComponent
              class="pt-8"
              title="HD 1.4 Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166266479?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166266479"
              allow="autoplay; fullscreen"
              id="player_4"
              name="fitvid3"
            ></iframe>
            <TitleComponent
              class="pt-8"
              title="HD 1.4s Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166266500?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166266500"
              allow="autoplay; fullscreen"
              id="player_5"
              name="fitvid4"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/IPSeriesSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
